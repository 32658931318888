@import "~bootstrap/scss/bootstrap";

.login { width: 100vw; height: 100vh; display: flex; justify-content: center; background-color: #FDFDFD;
    & .contentLogin{ display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
        & .panel{ min-width: 360px; padding: 68.53px 30px 79px; border-radius: 10px; box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08); 
            & .acessaForm{ margin-top: 60px;
                & h2{ margin-bottom: 60px; }
                & div{ display: flex; flex-direction: column;
                    & label{ color: #888888; font-size: 16px; font-weight: bold; text-align: left; }
                    & input{ border: none; border: 1px solid #838FA6; font-size: 20px; height: 46.94px; border-radius: 5px;
                        &::placeholder{ color: var(--cinzaMedio) }
                        &:last-child{ margin-top: 28.16px; }
                    }
                }
                & .btn.entrar{ height: 50px; margin-top: 33.41px; font-size: 18px; font-weight: bold; text-transform: uppercase;
                    color: var(--branco); background-color: var(--vermelho); width: 100%;
                }
            }
        }
    }
}

.dashboard{
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel{
        & .header{ display: flex; align-items: center;
            & .filtros{
                & button{ border: 1px solid var(--azulMedio); color: var(--azulMedio); font-size: 15px; width: 120px; border-radius: 20px; margin-right: 20px; }
                & button.active{ background-color: var(--azulMedio); color: var(--branco); }
            }
            & .faturamento{ margin-left: auto; text-align: right;
                p{ margin-top: 0; margin-bottom: 0; }
                h3{ margin-top: 0; font-size: 24px; font-weight: bold; color: var(--vermelho); }
            }
        }
    }
    & .multiGraph{ display: flex; 
        & .panel{ display: flex; flex-direction: column; width: 50vw;
            & p.titulo{ font-size: 16px; font-weight: bold; color: var(--azulEscuro); }
            & canvas{ margin-top: auto; }
        }
        & .panel.mr{ margin-right: 60px; }
    }
}

.empresas{
    & .headerBtn{
        .buttons{
            margin-left: auto; 
            margin-right: 25px;
            button{
                margin-left: 25px;
            }
        };
        flex-wrap: wrap;
    }
    & .detalhes{
        & .panel{ margin-bottom: 22px; }
        & .info{ display: flex; align-items: center; margin-left: 29px;
            & p{ font-size: 16px; color: #4F4F4F; }
            & .paginacao{ margin-left: auto; }
        }
    }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
}

.usuario-empresa{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio);}
    & .headerBtn{
        .buttons{
            margin-left: auto; 
            margin-right: 25px;
            button{
                margin-left: 25px;
            }
        };
        flex-wrap: wrap;
    }
    
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .acoes{ display: flex; margin-top: 12px; 
        & button.btn.voltar, a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; }
        & button.btnCadastrar{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}

.funcionario-acompanhamento{
    & table{
        & td{
            max-height: 100px;     
        }
    }
}

.empresas-nova, .usuario-empresa-novo, .editar-colaboradores, .representante-novo{
    .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .panel{
        & .headerBtn
        {
            .btn{margin-left: auto;}
        }
        & .panelBody{
            & .multInput{  display: flex; flex-wrap: wrap;
                & label{ color: #4F4F4F; font-size: 14px; margin-bottom: 3px; }
                & input{ margin-right: 27px; margin-bottom:27px; padding: 9.5px 0; }
                & .btnInput{ margin-right: 27px; }
            }
            & .empresaInput{ padding-top: 16px; padding-bottom: 16px; border-bottom: 1px solid #DFDFDF; 
                &:last-child{ border-bottom: none; }
                & .data{ margin-top:15px; }
            }
        }
    }
    & .acoes{ display: flex; margin-top: 12px; 
        & button.btn.voltar, a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; }
        & button.btnCadastrar{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}

.colaboradores{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .headerBtn
    {
        .headerActions
        {
            margin-left: auto ;
            .btn{
                margin-right: 15px;
            }
        }
    }
}
.leads{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio);}
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
}
.editar-representante{
    & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }

}

.editar-colaboradores{
    & .headerBtn{ flex-wrap: wrap;
        button{ margin-left: 0; margin-left:25px; }
        .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    }
    & .panel .panelBody{
        & .multInput{ margin-bottom: 20px;
            &:last-child{ margin-bottom: 0; }
            & select{ margin-right: 27px; }
        }
        & .bola{ width: 20px; height: 20px; border-radius: 50%; margin-right: 10px; }
    }
}

.importacao-colaboradores{
    &  button{ margin-bottom: 10px }
    & .headerBtn{ flex-wrap: wrap;margin-top: 20px;
        button{ margin-left: 0; margin-left:25px; }
        .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    }
    & .panelHeader{margin-top: 30px;}
    & .panel .panelBody {
        & .row div{width: fit-content;}
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
         & .multInput{ margin-bottom: 20px;
            & .inputDefault{
                margin-bottom: 20px;
                & label input[type="checkbox"]{
                    margin-right: 10px;
                }
            }
            &:last-child{ margin-bottom: 0; }
            & select{ margin-right: 27px; }
        }
    }
    & .acoes{ display: flex; margin-top: 12px; 
        & button.btn.voltar, a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; }
        & button.btnCadastrar{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}

.documento-colaboradores{
    & .headerBtn{ flex-wrap: wrap;
    }
    & .panel{ flex-wrap: wrap;
        button{ margin-left: 0; margin-right:20px; width:200px; }
        .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    }
}

.colaboradores.change{
    .panel{
        .nome{ display:block; font-size:18px; font-weight:bold; margin-bottom:20px;}
        .pergunta{ display:block; margin-bottom:20px;
            .titulo{ display:block; font-size:18px; color:var(--vermelho); }
            .resposta{ display:block; font-size:16px; color:rgb(90, 2, 2);}
        }
        .pergunta:last-child{ margin-bottom: 0; }
    }
    .acoes{
        & .btn:first-child{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
        & .btn:last-child{ background-color: var(--azulEscuro); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; margin-left:20px; font-weight: 500; text-transform: uppercase; }
    }
}

.contratos{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); margin-left: 25px; }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .info{ display: flex; align-items: center; margin-left: 29px;
        & p{ font-size: 16px; color: #4F4F4F; }
        & .paginacao{ margin-left: auto; }
    }
  
}

.contratos-detalhes{
    & .headerBtn{
        & h1{ display: flex; align-items: center; }
        & .tag{ margin-left: 24px; width: 70px; padding: 3px 0; border-radius: 4px; font-size: 12px; color: var(--branco);  text-align: center; 
            &.aberto{ background-color: var(--verde); }
            &.finalizado{ background-color: var(--azulMedio); }
            &.cancelado{ background-color: var(--vermelho); }
        }
        & button{ margin-left: 25px; background-color: var(--branco); }
        & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    }
    & .detalhes{
        & .multArea{ display: flex; align-items: center; margin-bottom: 21px;
            & p{ font-size: 15px; font-weight: 500; color: var(--cinzaMedioEscuro); margin-right: 44px; margin-bottom: 0;
                & span{ color: var(--rosaEscuro); font-weight: normal; margin-left: 8px; }
            }
        }
    }
    & .historico-parcela{ display: flex;
        & .panel{
            &:first-child{ width: 40vw; margin-right: 40px; height: 265px; }
            &:last-child{ width: 60vw; height: 265px; }
            & .panelBody{ max-height: 223px; }

            & .table {
                & tr{ height: 50px; 
                    &:first-child{ height: unset; }
                }
                & td span{width: 91px;}
                & th{ color: var(--azulEscuro); }
            }
        }
    }
    & .acoes{ display: flex; align-items: center; 
        & a{ border: 1px solid #BDBDBD; color: var(--cinzaMedio); font-weight: 500; font-size: 16px; width: 110px; }
        & button{ background-color: var(--vermelho); color: var(--branco); font-size: 16px; font-weight: 500; text-transform: uppercase; margin-left: 21px; padding: 12.5px 16px; }
    }
}

.vencimentos{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); margin-left: 25px; }
    & .detalhes .panel{ max-height: 300px; 
        & .panelBody { max-height: 250px; }
    }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & input:not([type='checkbox']){ margin-right: 34px; padding: 10px; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .info{ display: flex; align-items: center;
        & p{ font-size: 16px; color: var(--cinzaMedioEscuro); 
            &:last-child{ color: var(--cinzaMedioEscuro); font-size: 15px; 
                & span{ color: var(--vermelho); font-size: 20px; font-weight: bold; margin-left: 25px; }
            }
        }
        & .paginacao{ margin-left: auto; }
    }
}
.parcelas-antecipadas{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); margin-left: 25px; }
    & .detalhes .panel{ max-height: 300px; 
        & .panelBody { max-height: 600px; }
    }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & input:not([type='checkbox']){ margin-right: 34px; padding: 10px; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px;margin-right: 10px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
        & .btn.reset{ background-color: var(--lvermelho); color: var(--branco); width: 175px; height: 45px;margin-right: 10px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }

    }
    & .info{ display: flex; align-items: center;
        & p{ font-size: 16px; color: var(--cinzaMedioEscuro); 
            &:last-child{ color: var(--cinzaMedioEscuro); font-size: 15px; 
                & span{ color: var(--vermelho); font-size: 20px; font-weight: bold; margin-left: 25px; }
            }
        }
        & .paginacao{ margin-left: auto; }
    }
    table{
        width: 200%;
    }
}
.modal-tipobaixa .inputDefault label{
         
    font-size: 15px;
        color: #c04e7a;
        color: var(--rosaEscuro);
        text-align: left;
        font-weight: 400;
}

.modal-parcelas-edit-colaboradores{
    overflow-y: scroll;
    max-height: 270px;
    //max-width: 350px;
}
.panel-contratos-simulacao{
    overflow-y: scroll;
    max-height: 350px;
    //max-width: 350px;
    & .table td.tag span{
        width: 100px;
    }
}

.faturas{
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .table{
        & tbody tr:last-child{
            & td{ padding-top: 14px; border-bottom: none;
                &:nth-child(4){ color: var(--cinzaMedioEscuro); font-size: 15px; text-align: right; }
                &:nth-child(5){ color: var(--vermelho); font-size: 20px; font-weight: bold; }
            }
        }
    }
}

.desligamentos{
    & .headerBtn{
        .buttons{
            margin-left: auto; 
            margin-right: 25px;
            button{
                margin-left: 25px;
            }
        };
        flex-wrap: wrap;
    }
    & .btnAzul{ border: 1px solid var(--azulMedio); color: var(--azulMedio); }
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
    & .info{ display: flex; align-items: center;
        & p{ font-size: 16px; color: var(--cinzaMedioEscuro); 
            &:last-child{ color: var(--cinzaMedioEscuro); font-size: 15px; 
                & .valor{ color: var(--vermelho); font-size: 20px; font-weight: bold; margin-left: 25px; }
            }
        }
        & .paginacao{ margin-left: auto; }
    }
       
}

.emprestimo-pessoal{
   
    & .panel .panelBody {
        & input{ margin-right: 34px; padding: 9.5px 0; }
        & select{ margin-right: 34px; }
        & .btn.filtrar{ background-color: var(--vermelho); color: var(--branco); width: 175px; height: 45px; margin-top: 19px; font-weight: 500; text-transform: uppercase; }
    }
}
.simulador-nao-conveniado
{
    & .panel{
        & .panelBody{ 
            & input{ margin-right: 5px; padding: 9.5px 0; }
            &.menus{
                & label{ color: #000 !important; font-size: 15px !important; }
                & .menu{ padding: 10px 0 10px; 
                    &:not(:last-child){ border-bottom: 1px solid #DFDFDF; }
                }
                // & .menu{ border-bottom: 1px solid #DFDFDF; padding: 10px 0 10px 10px; 
                //     &.filho{ border-top: 1px solid #DFDFDF; border-bottom: 0; margin-top: 10px; padding-bottom: 0; }
                // }
                // & .border{ border-bottom: 1px solid #DFDFDF; }
            }
        }
        & .panelBodyTable{ max-height: 500px;}
    }
    & .multiInput{ display: flex; align-items: center; flex-wrap: wrap; padding-bottom: 23px;
        & div{ margin-bottom: 20px; }
        & input{ padding: 10.5px; margin-right: 30px !important; }
        & .btnInput{ margin-right: 35px; }
        & select{ height: 50px; }
    }
    .actions{ display: flex; align-items: center; 
        & a{ border: 1px solid #BDBDBD; color: var(--cinzaMedio); font-weight: 500; font-size: 16px; width: 110px; 
            &:hover, &:focus{ background-color: var(--cinzaMedio); color: #FFF; }
        }
        & button{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}

.emprestimo-pessoal-detalhes{
    & .headerBtn{ display: block; 
        & h1{ margin: 0; }
    }
    & .tag{ margin-left: 24px;  text-align: center; 
        & p{ width: 70px; padding: 3px 0; border-radius: 4px; font-size: 12px; color: var(--branco); 
            &.quitado{ background-color: var(--verde); }
            &.inadimplente{ background-color: var(--amarelo); }
            &.cancelado{ background-color: var(--vermelho); }
        }
    }
    & .multPanel{ display: flex; align-items: center;
        & .panel{ width: 60%; height: 411px;
            &:first-child{ margin-right: 32px; width: 60%; }
            & .panelBody{ max-height:250px; }
        }
    }
    & .detalhes{
        & p{ font-size: 15px; font-weight: 500; color: var(--cinzaMedioEscuro); margin-right: 44px; margin-bottom: 0; margin-top: 0;
            & span{ color: var(--rosaEscuro); font-weight: normal; margin-left: 8px; margin-right: 8px; }
        }
        & .multArea{ display: flex; align-items: center; margin-bottom: 21px; }
    }
    & .acoes{ display: flex; margin-top: 12px; 
        & button.btn.voltar, a{ border: 1px solid #BDBDBD; color: #999999; font-weight: 500; font-size: 16px; width: 110px; }
        & button.btnCadastrar{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}

.configuracao{ 
    & .panel{
        & .panelBody{ 
            & input{ margin-right: 5px; padding: 9.5px 0; }
            &.menus{
                & label{ color: #000 !important; font-size: 15px !important; }
                & .menu{ padding: 10px 0 10px; 
                    &:not(:last-child){ border-bottom: 1px solid #DFDFDF; }
                }
                // & .menu{ border-bottom: 1px solid #DFDFDF; padding: 10px 0 10px 10px; 
                //     &.filho{ border-top: 1px solid #DFDFDF; border-bottom: 0; margin-top: 10px; padding-bottom: 0; }
                // }
                // & .border{ border-bottom: 1px solid #DFDFDF; }
            }
        }
    }
    & .multiInput{ display: flex; align-items: center; flex-wrap: wrap; padding-bottom: 23px;
        & div{ margin-bottom: 20px; }
        & input{ padding: 10.5px; margin-right: 30px !important; }
        & .btnInput{ margin-right: 35px; }
        & select{ height: 50px; }
    }
    .actions{ display: flex; align-items: center; 
        & a{ border: 1px solid #BDBDBD; color: var(--cinzaMedio); font-weight: 500; font-size: 16px; width: 110px; 
            &:hover, &:focus{ background-color: var(--cinzaMedio); color: #FFF; }
        }
        & button{ width: 175px; font-weight: 500; text-transform: uppercase; padding: 13px 0; margin-left: auto; background-color: var(--vermelho); color: var(--branco); }
    }
}
.panel{
    overflow-x:scroll; 
    word-break: break-word;
    & .panelBody{
        & .inputDefault{
            flex: 0 0 auto;
        }
    }
   
}

.solicitacoespendentes{
   
    white-space: nowrap;
}
