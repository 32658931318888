.panel-component{
    background-color: var(--branco); 
    padding: 24px 30px 27px 29px; 
    margin-bottom: 30px; 
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); 
    border-radius: 5px;
}

.panel-header{
    border-bottom: 1px solid #DFDFDF; 
    margin-bottom: 18.5px;
    & h3{ 
        color: var(--rosaEscuro); 
        font-size: 16px; 
        font-weight: bold; 
        margin: 0;
        padding-left: 4px; 
        padding-bottom: 7px; 
    }
}

.panel-actions
{
    margin-bottom: 20px;
}

.button-component{
    width: 175px; 
    font-weight: 500; 
    text-transform: uppercase; 
    padding: 13px 0; 
    margin-top: 10px;
    margin-bottom: 10px; 

    background-color: var(--vermelho);
    color: var(--branco);
    border-radius: 50px;
}

.button-outline-component{
    margin-top: 10px;
    margin-bottom: 10px; 
    border: 1px solid var(--vermelho); 
    width: 175px; 
    font-weight: 500; 
    font-size: 16px; 
    color: var(--vermelho);
    
    text-transform: uppercase; 
    padding: 13px 0; 
    border-radius: 50px;
    &:hover{ 
        background-color: var(--vermelho);
        color: var(--branco);
            
    }
}
.page-bottom-actions-component
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    & :last-child{
        margin-left: auto;
    }
}

.back-button-component{
    width: 175px; 
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px; 
    text-transform: uppercase; 
    padding: 13px 0; 
    border-radius: 50px;
    border: 1px solid #BDBDBD; 
    color: var(--cinzaMedio);
    &:hover{ 
        background-color: #D2D2D2; 
    }
}

.header-component{
    font-size: 30px; 
    color: var(--cinzaMedio); 
    margin-bottom: 0; 
}

.summary-body{    
    display: flex; 
    align-items: center; 
    margin-bottom: 23px;

    & p{ 
        font-size: 12px; 
        color: var(--cinzaMedio); 
        margin-top: 5px; 
    }
   
}

