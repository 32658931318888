@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*, body > * { font-family: 'Roboto' !important; }
body { background-color: #F8F9FB; padding: 0; margin: 0; }
a{ text-decoration: none; }
button{ outline: none; cursor: pointer; box-sizing: border-box; border: none; background-color: transparent; }
button.btn,a.btn{ padding: 10px 0; font-size: 16px; border-radius: 4px; transition: ease-in-out .3s; text-align: center; }
select, input{ outline: none; }
select{ cursor: pointer; border-color: #DFDFDF; height: 44px; background-color: var(--branco); font-size: 16px; padding-left: 10px; border-radius: 2px; }
input{ background: var(--branco); color: #4F4F4F; border: 1px solid #DFDFDF; box-sizing: border-box; border-radius: 2px; font-size: 20px; padding-left: 10px !important; 
    &.disabled{ background-color: rgba(84, 84, 84, 0.1); border-color: rgba(118, 118, 118, 0.3); }
}
.container { padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
.inputDefault{ display: flex; flex-direction: column; flex-grow: 1 }
.inputTextArea{ outline: none;};
input.custom{ padding: 9.5px 0; }
// CUSTOM
:root{
    --azulEscuro: #2F3A59;
    --azulMedio: #3E4F74;
    --rosaEscuro: #C04E7A;
    --vermelho: #EA5353;
    --lvermelho: #ee7171;
    --amarelo: #F3945D;
    --laranja: #F3945D;
    --cinzaMedio: #999999;
    --cinzaMedioEscuro: #4F4F4F;
    --cinzaPreto: #333333;
    --verdeClaro: #6FCF97;
    --verde: #27AE60;
    --branco: #FFFFFF;
}
.body{ margin-left: 240px; }

.sideMenuBar{ width: 240px; height: 100%; position: fixed; left: 0; background-color: var(--vermelho); overflow:auto;
    & .profile{ margin: 31px 25px 38px; text-align: center;
        & p{ font-size: 16px; font-weight: bold; margin-top: 20.49px; color: var(--branco); }
    }
    & .navigation{ margin-bottom:70px;
        & ul{ list-style: none; padding: 0; margin: 25px 25px 0 25px;
            & a{ color: var(--branco); display: flex; padding: 13px 0 13px 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.3); font-weight: 500; 
                font-size: 16px; align-items: center;text-decoration: none;
                & img{ margin-right: 15px; }
                &:hover, &:focus{ background-color: rgba(255, 255, 255, 0.1); border-radius: 4px; }
            }
            & a.active{ background-color: rgba(255, 255, 255, 0.3); border-bottom: none; border-radius: 4px; }
            & a.noBorder{ border-bottom: none; }
            & .menuIcone{ display: flex; flex-direction: row; align-items: center; position: relative; 
                & > div > svg{ position: absolute; top: 50%; transform: translateY(-50%); }
                & span{ margin-left: 32px; }
            }
            & .svgAbsolute{ margin-right: 32px; position: relative;
                & > svg{ position: absolute; top: 50%; transform: translateY(-50%); }
            }

            & .dropdown-btn{ display: block; cursor: pointer; display: flex; align-items: center; margin: 0;
                &.ativo{ background-color: rgba(255, 255, 255, 0.3); border-radius: 4px 4px 0 0; }
                & .iconExpandir{ transform: none; transition-duration: 350ms; transition-property: transform;
                    &.ativo{ transform: rotate(180deg); }
                }
            }
            & .dropdown-container{ display: none; height: 0; overflow: hidden; transition: height 350ms ease-in-out; background-color: rgba(255, 255, 255, 0.3); 
                padding-left: 17px; list-style: none; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;
                &.active{ display: block; height: auto; }
                & a{ font-weight: normal; 
                    &:hover{ background: none; font-weight: 500; }
                }
            }
        }
    }
}

.navbar { display: flex; align-items: center; background-color: var(--branco); padding: 2px 0; box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    & .barLogo { margin-left: 33px;
        & img { width: 103px; }
    }
    & .barLinks{ margin-left: auto; margin-right: 33px;
        & ul{ list-style: none; padding: 0;
            & a{ display: flex; align-items: center; color: #4F4F4F; font-size: 14px; font-weight: bold;
                & img{ margin-left: 7px; }
            }
        }
    }
}

.footer { flex: 0; text-align: center; background-color: #EEEEEE; padding: 2.5px 20px; position: fixed; width: 100%; bottom: 0;
    & p { font-size: 12px; color: #999999; }
}

.paginacao{ display: flex; align-items: center;
    & button{ font-size: 18px; color: var(--azulEscuro); margin-left: 11px; padding: 10.5px 16.6px; font-weight: bold; border: 1px solid #E0E0E0; 
        box-sizing: border-box; border-radius: 5px; transition: .2s; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); background: var(--branco);
    }
    & button.disabled, button.disabled:hover{ color: #999; border: 1px solid #999999; cursor: default; }
    & button.active, button:hover{ color: var(--vermelho); border: 1px solid var(--vermelho); }
    & button.btnPageExtremos{ width: 45px; height: 42px; position: relative;
        & span{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    }
}

.table { border-collapse: collapse; width: 100%;color:#e7e7e7;
    & td, th{ border-bottom: 1px solid #DFDFDF; padding: 11px 20px; }
    & td{ border-bottom: 1px solid #DFDFDF; font-size: 15px; color: #4F4F4F; 
        &.tag{
            & span{ padding: 3px 8px; border-radius: 4px; display: block; width: 71px; font-weight: bold; font-size: 12px; text-align: center; }
        }
    }
    & th{ font-size: 15px; color: var(--rosaEscuro); text-align: left; font-weight: normal; }
}


input[type="checkbox"].checkBoxtable{
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 1px solid #999999;
    border-radius: 2px;
    position: relative;
    width: 17px;
    height: 17px;
    transition: all .1s linear;
    padding-left: 0 !important;

    &:checked{ background-color: var(--vermelho); }
    &:focus{ outline: 0 none; box-shadow: none; }
}

input[type="file"]{
    display: none;
}

.panel{ background-color: var(--branco); padding: 24px 30px 27px 29px; margin-bottom: 30px; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); border-radius: 5px; 
    & .panelHeader{  border-bottom: 1px solid #DFDFDF; margin-bottom: 18.5px;
        & h3{ color: var(--rosaEscuro); font-size: 16px; font-weight: bold; margin: 0; padding-left: 4px; padding-bottom: 7px; }
    }
}

.mgDefault{ margin: 44px 40px 80px; }
.headerBtn{ display: flex; align-items: center; margin-bottom: 23px;
    & h1{ font-size: 30px; color: var(--cinzaMedio); margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: var(--cinzaMedio); margin-top: 5px; }
    & button{ margin-left: auto;margin-bottom: 8px; border: 1px solid var(--vermelho); width: 175px; font-weight: 500; font-size: 16px; color: var(--vermelho); }
 }
.textEmpty{ color: #ABABAB; font-size: 20px }
.modal, .alert{
    p{ font-size: 16px; 
        &.warning{ text-transform: uppercase; color: var(--cinzaMedio); font-weight: bold; }
        &.description{ margin-top: 23px; margin-bottom: 21px; }
    }
    .btn.alert{ background-color: var(--azulMedio); color: var(--branco); width: 100%; text-transform: uppercase; font-weight: 500; font-size: 16px; }
    & .boletoGerado{ display: flex; flex-direction: row;
        & input{ border-top-right-radius: 0; border-bottom-right-radius: 0; }
        & button{ padding: 5.5px; border-top-right-radius: 2px; border-bottom-right-radius: 2px; border: 1px solid #dfdfdf; border-left: none; }
    }
    & p.close{ text-align: right; font-size: 25px; margin-top: 0; margin-bottom: 0; cursor: pointer; }
    & button.visualizaBoleto{ width: 100%; background-color: var(--azulMedio); color: var(--branco); font-weight: 500; margin-top: 15px; }
}
.modal.warning{
    & p{
        &:first-child{ font-size: 16px; color: var(--cinzaMedio); text-transform: uppercase; font-weight: bold; margin-bottom: 22px }
        &:last-child{ margin-top: 23px; margin-bottom: 22px; font-size: 16px }
    }
}
.cancelamentoAcao{ display: flex; align-items: center; margin-top: 27px;
    button{ width: 50vw; background-color: var(--vermelho); color: var(--branco); text-transform: uppercase; font-size: 16px; font-weight: 500;
        &:first-child{ margin-right: 20px; }
        &:last-child{ background-color: var(--cinzaMedio); }
    }
}
.btnVoltar{ border: 1px solid #BDBDBD; color: var(--cinzaMedio);
    &:hover{ background-color: #D2D2D2; }
}
.btnNovo{ background-color: var(--vermelho); color:var(--branco); padding-left:20px !important; padding-right:20px !important; }

input::placeholder{ color: #d0d0d0; }

.nao-encontrada h2{ margin-left: 20px; position: absolute; top: 50%; left: calc(50% + 75px); transform: translate(-50%, -50%); font-size: 36px; color: #BDBDBD; }